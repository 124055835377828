.gallery {
    position: relative; /* Necessary for z-index to work */
    width: 90%;
    height: 90%;
    max-width: 800px;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .gallery-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative; /* Ensure the content is positioned for proper stacking */
  }
  
  .gallery-image {
    position: absolute;
    width: 300px;
    height: auto;
    opacity: 0;
    transition: opacity 1s ease-in-out; /* Fade transition */
    border-radius: 5px
  }
  
  .gallery-image.visible {
    opacity: 1;
  }
  
  .gallery-image.hidden {
    opacity: 0;
  }
  
  .nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    color: rgba(0, 0, 0, 0.2);
    border: none;
    font-size: 2rem;
    cursor: pointer;
    padding: 0.5rem;
    transition: color 500ms ease;
  }
  
  .nav-button:hover {
    color: rgba(0, 0, 0, 1);
  }
  
  .prev {
    left: 0;
  }
  
  .next {
    right: 0;
  }