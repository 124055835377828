.container {
    margin: auto;
    margin-top: 25vh; /* Ensure content starts below the header */
    max-width: 1100px
  }
  
  h1 {
    text-align: center;
  }
  
  .intro, .key-highlights {
    margin-bottom: 20px;
  }
  
  p {
    margin: 0 0 10px;
  }
  
  .gallery {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .footer {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .footer a {
    text-decoration: none;
    color: #333;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }