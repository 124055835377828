.item {
    position: relative;
    z-index: 0; /* Ensure this is lower than your header's z-index */
    width: 50vw;
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5%;
    cursor: pointer;
    overflow: hidden;
}

/* Top border */
.item::before,
.item::after,
.item::first-line,
.item::first-letter {
    z-index: 0; /* Match the z-index of the parent or lower */
}

.item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 0;
    background-color: #000;
    transition: width 0.25s ease-in-out;
}

.item::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 0;
    width: 2px;
    background-color: #000;
    transition: height 0.25s ease-in-out 0.25s;
}

.item::first-line {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 2px;
    width: 0;
    background-color: #000;
    transition: width 0.25s ease-in-out 0.5s;
}

.item::first-letter {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 2px;
    background-color: #000;
    transition: height 0.25s ease-in-out 0.75s;
}

.item:hover::before {
    width: 100%;
}

.item:hover::after {
    height: 100%;
}

.item:hover::first-line {
    width: 100%;
}

.item:hover::first-letter {
    height: 100%;
}
