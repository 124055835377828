.imagesContainer {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.imageContainer {
    position: relative;
    width: 50vw;
    height: 50vw;
    display: flex;
    transition: width 0.5s ease-in-out;
}

.imageContainer.hover {
    width: 60vw;
}

.imageContainer.nothover {
    width: 40vw;
}

.textOverlay {
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: white;
    font-size: 2vw; /* Adjust font size as needed */
    text-shadow: 0px 0px 10px white;
    
}


.mainImage {
    position: relative;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(0);
    transition: 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit:cover;
    background-position: 0% 100%;
}

.mainImage.hover {
    width: 60vw;
}

.mainImage.nothover {
    width: 40vw;
    filter: blur(8px) brightness(0.5);
}